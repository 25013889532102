






















import Button from "../../commons/inputsAndControls/Button.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { UpdateProcessSubCodeInput } from "../../../types/gqlGeneratedPrivate";
import { ValidationObserver } from "vee-validate";
import SwitchField from "../../commons/inputsAndControls/SwitchField.vue";

@Component({
  components: {
    SwitchField,
    Button,
    TextField,
    ValidationObserver,
  },
})
export default class ProcessSubCodeForm extends Vue {
  @Prop({ type: Object, required: true })
  value!: UpdateProcessSubCodeInput;

  async validate(): Promise<boolean> {
    return (this.$refs.observer as any).validate();
  }

  reset(): void {
    (this.$refs.observer as any).reset();
  }
}
