













































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { api } from "../../../api";
import {
  OperationTemplateListQuery,
  OperationTemplateListQueryVariables,
  UpdateProcessSubCodeInput,
  UpdateProcessSubCodeMutation,
  UpdateProcessSubCodeMutationVariables,
} from "../../../types/gqlGeneratedPrivate";
import { apiClient, alerts } from "../../../utils";
import Button from "../../commons/inputsAndControls/Button.vue";
import RoundButton from "../../commons/inputsAndControls/RoundButton.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import Autocomplete from "../../commons/inputsAndControls/Autocomplete.vue";
import { ValidationObserver } from "vee-validate";
import Textarea from "../../commons/inputsAndControls/Textarea.vue";
import SwitchField from "../../commons/inputsAndControls/SwitchField.vue";

interface OperationTemplate {
  id: number;
  name: string;
}

interface UpdateProcessSubCode {
  id: number;
  name: string;
  code: string;
  isCadCam: boolean;
  operationTemplateIds: number[];
  operationTemplates: OperationTemplate[];
}

@Component({
  components: {
    SwitchField,
    Button,
    RoundButton,
    TextField,
    Textarea,
    Autocomplete,
    ValidationObserver,
  },
})
export default class ProcessSubCodeUpdateForm extends Vue {
  @Prop({ required: true, type: Object })
  data!: UpdateProcessSubCode;

  @Watch("data", { immediate: true })
  onDataChange() {
    this.editedItem = {
      ...this.data,
    };
    this.resetAutocomplete();
  }

  resetAutocomplete() {
    this.$nextTick(() => {
      // A workaround to manually update stale model of the autocomplete box,
      // because the :value property does not work in all situations
      // v-autocomplete inside ValidationProvider inside Autocomplete
      const autocompleteBox = (this.$refs.autocomplete as any).$children[0]
        .$children[0];
      autocompleteBox.lazyValue = null;
    });
  }

  editedItem: UpdateProcessSubCode | null = null;

  allSubItems: OperationTemplate[] = [];

  async created() {
    const { data } = await apiClient.callGraphqlPrivate<
      OperationTemplateListQuery,
      OperationTemplateListQueryVariables
    >({
      ...api.operationTemplates.operationTemplateList,
      variables: {
        input: {
          pager: {
            size: 10000,
            page: 1,
          },
        },
      },
    });
    if (data?.operationTemplates) {
      this.allSubItems = data.operationTemplates.items;
    }
  }

  get allSubItemsForAutocomplete(): OperationTemplate[] {
    if (this.editedItem) {
      const allowedSubItems = [];
      const subItemIdsToExclude = this.editedItem.operationTemplates;
      for (const item of this.allSubItems) {
        if (!subItemIdsToExclude.some((x) => x.id === item.id)) {
          allowedSubItems.push(item);
        }
      }
      return allowedSubItems;
    } else {
      return [];
    }
  }

  get autocompleteLabel(): string {
    if (this.allSubItemsForAutocomplete.length > 0) {
      return "Přidat";
    } else {
      return "Žádné další položky";
    }
  }

  addSubItem(subItemKey: number) {
    if (this.editedItem) {
      const subCodeIndex = this.allSubItems.findIndex(
        (item) => item.id === subItemKey
      );
      const subItem = this.allSubItems[subCodeIndex];
      if (subItem) {
        this.editedItem = {
          ...this.editedItem,
          operationTemplates: [...this.editedItem.operationTemplates, subItem],
        };
      } else {
        throw new Error(
          "Unexpected internal state of ProcessSubCodeUpdateForm component."
        );
      }
    }
    this.resetAutocomplete();
  }

  deleteSubItem(toBeDeleted: OperationTemplate) {
    if (this.editedItem) {
      const subItems = [...this.editedItem.operationTemplates];
      const index = subItems.findIndex((item) => item.id === toBeDeleted.id);
      if (index > -1) {
        subItems.splice(index, 1);
      }
      this.editedItem = {
        ...this.editedItem,
        operationTemplates: subItems,
      };
    }
    this.resetAutocomplete();
  }

  async submitUpdate() {
    if (this.editedItem) {
      const updatedItem: UpdateProcessSubCodeInput = {
        code: this.editedItem.code,
        name: this.editedItem.name,
        isCadCam: this.editedItem.isCadCam,
        operationTemplateIds: this.editedItem.operationTemplates.map(
          (item) => item.id
        ),
      };

      const result = await apiClient.callGraphqlPrivate<
        UpdateProcessSubCodeMutation,
        UpdateProcessSubCodeMutationVariables
      >({
        ...api.processSubCodes.updateProcessSubCode,
        variables: {
          id: this.editedItem.id,
          input: updatedItem,
        },
      });
      if (result.data) {
        alerts.addSuccessAlert("UPDATE_PROCESS_SUB_CODE", "Uloženo");
        this.$emit("updated");
      }
    }
  }

  @Watch("editedItem", { deep: true })
  onInput(a: any, b: any) {
    if (JSON.stringify(a) !== JSON.stringify(b)) {
      this.$emit("changed");
    }
  }
}
