






































import { Component, Vue } from "vue-property-decorator";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import Table from "../components/commons/tables/Table.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import RoundButton from "../components/commons/inputsAndControls/RoundButton.vue";
import { TableOptions, TableRefreshPayload } from "../types";
import {
  ProcessSubCode,
  ProcessSubCodesFilterEnum,
} from "../types/gqlGeneratedPrivate";
import { TablesActions, TablesNamespaces } from "../store/modules/tables";
import { EventBus } from "../enums/EventBus";
import { eventBus } from "../utils/eventBus";
import ProcessSubCodeDeleteModal from "../components/app/processSubCodes/ProcessSubCodeDeleteModal.vue";
import ProcessSubCodeCreateModal from "../components/app/processSubCodes/ProcessSubCodeCreateModal.vue";
import ProcessSubCodeUpdateForm from "../components/app/processSubCodes/ProcessSubCodeUpdateForm.vue";
import { Action } from "vuex-class";

interface UpdateSubCode {
  id: number;
  name: string;
  code: string;
  operationTemplateIds: number[];
  operationTemplates: Array<{
    id: number;
    name: string;
  }>;
}

@Component({
  components: {
    AppLayout,
    Button,
    RoundButton,
    Table,
    TableHeader,
    ActionsWrapper,
    ProcessSubCodeDeleteModal,
    ProcessSubCodeCreateModal,
    ProcessSubCodeUpdateForm,
  },
})
export default class ProcessSubCodesPage extends Vue {
  editedItem: UpdateSubCode | null = null;
  isFormDirty = false;

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.ProcessSubCodesTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  tableOptions: TableOptions<ProcessSubCode, ProcessSubCodesFilterEnum> = {
    defaultSortBy: ["code"],
    headers: [
      {
        text: "Podkód",
        sortable: true,
        align: "start",
        value: "code",
      },
      {
        text: "Název",
        sortable: true,
        align: "start",
        value: "name",
      },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    namespace: TablesNamespaces.ProcessSubCodesTable,
    key: "id",
  };

  rowClick(row: any) {
    this.updateItem(row.item, row);
  }

  addItem(processSubCode: ProcessSubCode): void {
    eventBus.$emit(EventBus.ProcessSubCodeCreateModal, processSubCode);
  }

  deleteItem(processSubCode: ProcessSubCode): void {
    eventBus.$emit(EventBus.ProcessSubCodeDeleteModal, processSubCode);
  }

  updateItem(item: UpdateSubCode, row: any): void {
    row.select();
    this.editedItem = { ...item };
  }

  onItemDeleted() {
    this.editedItem = null;
    this.isFormDirty = false;
  }

  onItemCreated() {
    this.editedItem = null;
    this.isFormDirty = false;
  }

  onItemUpdated() {
    this.refreshTable({
      namespace: TablesNamespaces.ProcessSubCodesTable,
    });
    this.isFormDirty = false;
  }

  onFormChanged() {
    this.isFormDirty = true;
  }
}
